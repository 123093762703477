import Alert from "react-bootstrap/Alert";

const AlertEnum = {
  SUCCESS: "success",
  DANGER: "danger",
  WARNING: "warning",
  INFO: "info",
};

function AlertMess(props) {

  const show = props.show;
  const setShow = props.setShow;
  const typeMessage = props.typeMessage;
  const message = props.message;

  return (
    <>
      <div className="containerAlert">
        <Alert
          show={show}
          variant={typeMessage}
          onClose={() => setShow(false)}
          transition={true}
          dismissible
        >
          <p>{message}</p>
        </Alert>
      </div>
    </>
  );
}

 

export {AlertEnum, AlertMess };
