import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter, Router, Routes, Route } from "react-router-dom";
import Appartamenti from "./components/Pages/Appartamenti/appartamenti";
import Tariffe from "./components/Pages/Tariffe/tariffe";
import Dintorni from "./components/Pages/Dintorni/dintorni";
import Contatti from "./components/Pages/Contatti/contatti";
import Prenota from "./components/Pages/Prenota/Prenota";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/Appartamenti" element={<Appartamenti />} />
        <Route path="/Tariffe" element={<Tariffe />} />
        <Route path="/Dintorni" element={<Dintorni />} />
        <Route path="/Contatti" element={<Contatti />} />
        <Route path="/Prenota" element={<Prenota />} />
      </Routes>
    </BrowserRouter>
);
