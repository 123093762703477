import React from "react";
import Footer from "../../footer/footer";
import Navbar from "../../navbar/navbarmenu";
import Iframe from "react-iframe";
import "./contatti.css";

const contatti = [
  {
    cont: "tel:+39334.7813920",
    testo: "Sig.ra Carla Gualandri: Tel. 334.7813920",
  },
  {
    cont: "tel:+.39348.6404070",
    testo: "Sig.re Adriano Gatti: Tel. 348.6404070",
  },
  {
    cont: "mailto:bbamarena.carpineti@gmail.com",
    testo: "Mail: bbamarena.carpineti@gmail.com",
  },
  {
    testo: "B&B Amarena Via Belvedere, 37 42033 Carpineti ( Reggio Emilia )",
  },
];

function Contatti() {
  return (
    <>
      <Navbar />
      <div className="container">
        <Iframe
          url="https://www.sdrive.app/embed/1ptBQD"
          className="map"
          display="block"
          position="relative"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1268.6525609046982!2d10.51062604468886!3d44.45308424260278!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12d553b22e87a7f3%3A0x4e0d108b9627eaab!2sB%26B%20Amarena!5e0!3m2!1sit!2sit!4v1627215641853!5m2!1sit!2sit"
          width="600"
          height="450"
          allowfullscreen=""
          loading="eager"
        />
        <h3 className="title2">Per Info e prenotazione</h3>
        {contatti.map((row) => {
          return (
            <p>
              <a href={row.cont} key={row.cont}>{row.testo}</a>
            </p>
          );
        })}
      </div>
      <Footer />
    </>
  );
}

export default Contatti;
