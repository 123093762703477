import Navbar from "../../navbar/navbarmenu";
import Footer from "../../footer/footer";
import { Box, Button, Stack, TextField } from "@mui/material";
import styled from "@emotion/styled";
import "./prenota.css";
import { useState } from "react";
import { useCallback } from "react";
import moment from "moment";
import { AlertEnum, AlertMess } from "../../utility/AlertMess";
import { Spin } from "antd";
import { prenotazione } from "../../Rest/restApi";

const convertData = (data) => moment(data).format("YYYY-MM-DD");

const Input = styled(TextField)({
  "& label.Mui-focused": {
    color: "#3818de",
  },
  "& label": {
    color: "#3818de",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#0000b3",
    },
    "&:hover fieldset": {
      borderColor: "#3818de",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#3818de",
    },
  },
});

export default function Prenota() {
  const [nome, setNome] = useState(null);
  const [nomeErr, setNomeErr] = useState(false);

  const [cognome, setCognome] = useState(null);
  const [congomeErr, setCognomeErr] = useState(false);

  const [email, setEmail] = useState(null);
  const [emailErr, setEmailErr] = useState(false);

  const [nTel, setNTel] = useState(null);
  const [nTelErr, setNtelErr] = useState(false);

  const [Nper, setNPer] = useState(null);
  const [NperErr, setNperErr] = useState(false);

  const [dtaa, setDtaa] = useState(null);
  const [dtaaErr, setDtaaErr] = useState(false);

  const [dtp, setDtp] = useState(null);
  const [dtpErr, setDtpErr] = useState(false);

  const [note,setNote] = useState(null)

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState(null);
  const [typeMessage, setTypeMessage] = useState(null);

  const [loading, setLoading] = useState(false)

  const check = (nome, cognome, email, nTel, Nper, DataA, DataP) => {
    if (
      nome === undefined ||
      nome === null ||
      nome.replaceAll(" ", "").length === 0
    ) {
      setShow(true);
      setMessage("Nome non valido");
      setTypeMessage(AlertEnum.DANGER);
      setNomeErr(true);
      return false;
    }

    if (
      cognome === undefined ||
      cognome === null ||
      cognome.replaceAll(" ", "").length === 0
    ) {
      setShow(true);
      setMessage("Cognome non valido");
      setTypeMessage(AlertEnum.DANGER);
      setCognomeErr(true);
      return false;
    }

    if (
      email === undefined ||
      email === null ||
      email.replaceAll(" ", "").length === 0
    ) {
      setShow(true);
      setMessage("Email non valida");
      setTypeMessage(AlertEnum.DANGER);
      setEmailErr(true);
      return false;
    }

    if (
      isNaN(nTel) ||
      nTel === undefined ||
      nTel === null ||
      nTel.replaceAll(" ", "").length === 0
    ) {
      setShow(true);
      setMessage("Numero di telefono non valido");
      setTypeMessage(AlertEnum.DANGER);
      setNtelErr(true);
      return false;
    }

    if (
      Nper === undefined ||
      Nper === null ||
      Nper.replaceAll(" ", "").length === 0 ||
      Nper <= 0 ||
      Nper >= 11
    ) {
      setShow(true);
      setMessage("Numero di persone non valido");
      setTypeMessage(AlertEnum.DANGER);
      setNperErr(true);
      return false;
    }

    const today = new Date();

    if (
      DataA === undefined ||
      DataA === null ||
      DataA === "Invalid Date" ||
      convertData(DataA) === convertData(today) ||
      convertData(DataA) < convertData(today)
    ) {
      setShow(true);
      setMessage("Data di arrivo non valido");
      setTypeMessage(AlertEnum.DANGER);
      setDtaaErr(true);
      return false;
    }

    if (
      DataP === undefined ||
      DataP === null ||
      DataP === "Invalid Date" ||
      convertData(DataP) === convertData(today)
    ) {
      setShow(true);
      setMessage("Data di partenza non valida");
      setTypeMessage(AlertEnum.DANGER);
      setDtpErr(true);
      return false;
    }

    if (DataA > DataP) {
      setShow(true);
      setMessage("Data di arrivo deve essere minore della data di partenza");
      setTypeMessage(AlertEnum.DANGER);
      setDtaaErr(true);
      setDtpErr(true);
      return false;
    }

    if (DataA === DataP) {
      setShow(true);
      setMessage("Data di arrivo non possono essere uguali");
      setTypeMessage(AlertEnum.DANGER);
      setDtaaErr(true);
      setDtpErr(true);
      return false;
    }
    return true;
  };

  const handleClick = useCallback(() => {
    if (check(nome, cognome, email, nTel, Nper, dtaa, dtp)) {
      setLoading(true)
      const jsondata = {
        id: null,
        nome: nome,
        cognome: cognome,
        email: email,
        cellulare: nTel,
        numospiti: Nper,
        datada: dtaa,
        dataa: dtp,
        typepren: 1,
        accept: false,
        oldPren: false,
        status: 1,
        prenottamento: false,
        note: note
      };

      prenotazione(jsondata)
        .then((result) => {
          setLoading(false)
          if (result.status === 200) {
            setShow(true);
            setMessage(
              "Prenotazione eseguita correttamente le invieremo una mail di conferma"
            );
            setTypeMessage(AlertEnum.SUCCESS);
            setNome(null);
            setCognome(null);
            setEmail(null);
            setNTel(null);
            setNPer(null);
            setDtaa(null);
            setDtp(null);
            setNote(null)
          } else {
            setShow(true);
            setMessage("OhOh qualcosa è andato storto riprova!");
            setTypeMessage(AlertEnum.DANGER);
          }
        })
        .catch((error) => {
          setLoading(false)
          setShow(true);
          setMessage("OhOh qualcosa è andato storto riprova! ");
          setTypeMessage(AlertEnum.DANGER);
        });
    }
  }, [Nper, cognome, dtaa, dtp, email, nTel, nome, note]);

  return (
    <div>
      <Navbar />
      <AlertMess
        show={show}
        setShow={setShow}
        typeMessage={typeMessage}
        message={message}
      />
      <Spin spinning={loading}>


        <Box sx={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", p: 4, "& .MuiTextField-root": { width: '45%' } }}>

          <TextField
            label="NOME"
            fullWidth
            error={nomeErr}
            value={nome || ""}
            margin="normal"
            focused
            onChange={(e) => setNome(e.target.value)}
          />
          <TextField
            label="COGNOME"
            id="custom-css-outlined-input"
            fullWidth
            margin="normal"
            error={congomeErr}
            value={cognome || ""}
            focused
            onChange={(e) => setCognome(e.target.value)}
          />

          <TextField
            label="EMAIL"
            id="custom-css-outlined-input"
            margin="normal"
            type="email"
            error={emailErr}
            value={email || ""}
            fullWidth
            focused
            onChange={(e) => setEmail(e.target.value)}
          />

          <TextField
            label="NUMERO TELEFONO"
            id="custom-css-outlined-input"
            type="tel"
            margin="normal"
            fullWidth
            error={nTelErr}
            inputProps={{ inputMode: "numeric", pattern: "/[0-9]/" }}
            value={nTel || ""}
            focused
            onChange={(e) => setNTel(e.target.value)}
          />
          <TextField
            label="NUMERO PERSONE"
            id="custom-css-outlined-input"
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            type="number"
            margin="normal"
            error={NperErr}
            value={Nper || ""}
            fullWidth
            focused
            onChange={(e) => setNPer(e.target.value)}
          />
          <TextField
            label="DATA DI ARRIVO"
            id="custom-css-outlined-input"
            type="date"
            focused
            margin="normal"
            error={dtaaErr}
            value={dtaa || 'gg/mm/aaaa'}
            fullWidth
            onChange={(e) => setDtaa(e.target.value)}
          />
          <TextField
            label="DATA DI PARTENZA"
            id="custom-css-outlined-input"
            type="date"
            margin="normal"
            error={dtpErr}
            value={dtp|| 'gg/mm/aaaa'}
            onChange={(e) => setDtp(e.target.value)}
            focused
            fullWidth
          />
            <TextField
            label="NOTE"
            id="custom-css-outlined-input"
            placeholder="scrivi se vuoi un messeggio"
            type="date"
            margin="normal"
            value={note|| ""}
            onChange={(e) => setNote(e.target.value)}
            focused
            fullWidth
            multiline
          />
          <div className="buttonsend">
            <Button variant="contained" onClick={handleClick}>
              Prenota
            </Button>
          </div>

        </Box>




      </Spin>

      <Footer />
    </div>
  );
}
