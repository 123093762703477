
import axios from "axios";

const api = axios.create({
   // baseURL: "http://localhost:3001/",
    baseURL: "https://mygestionalws.onrender.com",
    timeout: 120000
})

export {api}
