import React from 'react'
import Footer from '../../footer/footer';
import Navbar from '../../navbar/navbarmenu';

function Dintorni(){
    return(
        <>
        <Navbar />
        <Footer/>
        </>
    );
}

export default Dintorni;