import React from "react";
import Footer from "../../footer/footer";
import Navbar from "../../navbar/navbarmenu";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFlip, Pagination, Navigation, Controller } from "swiper/modules";
/* import img1 from "../../imm/sala2.jpg";
import img2 from "../../imm/camera3.jpg"; */
import img4 from "../../imm/sala1.jpg";
import img5 from "../../imm/camera1.jpg";
import img6 from "../../imm/camera2.jpg";
import "./appartamenti.css";
import "swiper/css";
import "swiper/css/effect-flip";
import "swiper/css/pagination";
import "swiper/css/navigation";

export default function Appartamenti() {
  return (
    <>
      <Navbar />
      <div className="container">
        <div className="piano">
          <h1 className="title2">APPARTAMENTI</h1>
          {/* <p>
            La struttura è costituita da un’unità abitativa disposta su due
            piani in modo indipendente.
          </p> */}

          {/* <h2 className="title2">Primo piano</h2>
          <p>
            Al primo piano è situato un piccolo appartamento dotato di tutti i
            confort, costituito da una camera da letto con bagno privato, sala
            con poltrona, divano e tv color, ed un piccolo dehor per godersi in
            relax la tranquillità del luogo.
          </p> */}
          {/* <br></br>
          <Swiper
            effect={"creative"}
            autoplay={{
              delay: 2500,
              disableOnInteraction: true,
            }}
            modules={[Autoplay, EffectFlip, Pagination, Navigation, Controller]}
            className="mySwiper"
            style={{
              width: 400
            }}
          >
            <SwiperSlide>
              <img src={img1} alt="img1"  />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img2} alt="img2"  />
            </SwiperSlide>
          </Swiper> */}

         {/*  <h2 className="title2">Secondo piano</h2> */}
          {/* <p>
            Al secondo piano è situato l’appartamento principale, costituito da
            due camere da letto, una sala con tv color e divano , la cucina con
            angolo cottura, due bagni , per un’accoglienza totale di 4 persone.
            Un ampio dehor con tavolini per colazione all’aperto è a
            disposizione degli ospiti dell’appartamento.
          </p> */}
          <p>
            é situato l’appartamento principale, costituito da
            due camere da letto, una sala con tv color e divano , la cucina con
            angolo cottura, due bagni , per un’accoglienza totale di 4 persone.
            Un ampio dehor con tavolini per colazione all’aperto è a
            disposizione degli ospiti dell’appartamento.
          </p>
          <br></br>
          <Swiper
            effect={"fade"}
            autoplay={{
              delay: 2500,
              disableOnInteraction: true,
            }}
            modules={[Autoplay, EffectFlip, Pagination, Navigation, Controller]}
            className="mySwiper"
            style={{
              width: 400
            }}
          >
            <SwiperSlide>
              <img src={img4} alt="img1"   />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img5} alt="img2"   />
            </SwiperSlide>
            <SwiperSlide>
              <img src={img6} alt="img2"  />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>

      <Footer />
    </>
  );
}
