import React from "react";
import "./App.css";
import "animate.css";
import Navbar from "./components/navbar/navbarmenu";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap";
import { Link } from "react-router-dom";
import img1 from "./components/imm/camera1.jpg";
import img3 from "./components/imm/fuori.jpg";
import Footer from "./components/footer/footer";


function Descrizione() {
  return (
    <div className="container">
      <div className="clearfix">

        <img src={img3} className="col-md-6 float-md-end mb-3 ms-md-3 rounded imgS" style={{ width: 500 }} alt="" />
        <p className="testo">
          B&B Amarena è una casa colonica di recentissima ristrutturazione, che,
          partendo da un edificio costituito originariamente da una casa-torre del
          sec. XV, ha cercato di mantenere inalterate le caratteristiche originali
          dell’abitazione dell’epoca inserendole in modo armonico in un contesto
          di funzionalità e confort abitativo. La sapiente opera degli architetti
          storici ha portato alla luce punti molto caratteristici dell’abitazione
          originale che l’ospite potrà così ammirare. Il B&B Amarena è di
          proprietà della famiglia Gualandri fin dalla fine del 1800 ed ancora gli
          eredi attuali ne tramandano con orgoglio la tradizione rurale originale,
          cercando di preservare quelle caratteristiche di accoglienza famigliare
          tipiche della gente del luogo. La struttura sorge all’estremità del
          centro abitato, in prossimità di un bosco di pini e carpini, a circa 800
          metri dal centro storico del paese, così da risultare in posizione
          strategica per le eventuali escursioni a piedi nei boschi,passeggiate in
          paese o gite in auto ai numerosi siti storici locali. Il paese di
          Carpineti offre quanto necessario alle eventuali necessità del turista:
          centro medico, farmacia comunale, negozi, bar, centro sportivo con
          piscina e campi da tennis, parco pubblico con punti giochi per i più
          piccoli ed una struttura coperta utilizzata per manifestazioni, eventi
          culturali, concerti ecc.
        </p>
      </div>
    </div>
  );
}

function Card() {
  return (
    <>
      <div className="col">
        <div className="card" style={{ width: "25rem", textAlign: "center" }}>
          <img src={img3} className="card-img-top" alt="img_esterna" />
          <div className="card-body">
            <h5 className="card-title">Tariffe</h5>
            <p className="card-text">
              Se vuoi scoprire quando costa, scopri le nostre tariffe.
            </p>
            <Link className="link btn btn-primary" to="/Tariffe">
              Scopri le tariffe
            </Link>
          </div>
        </div>
      </div>
      <div className="col">
        <div className="card" style={{ width: "25rem", textAlign: "center" }}>
          <img src={img1} className="card-img-top" alt="img_esterna" />
          <div className="card-body">
            <h5 className="card-title">Appartamenti</h5>
            <p className="card-text">
              Sei posti letto, divisi in una unità abbitativa, disposta su due
              piani dotate di tutti i confort.
            </p>
            <Link className="link btn btn-primary" to="/Appartamenti">
              Scopri gli appartamenti
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

function App() {

  return (
    <>
      <Navbar />
      <div className="text-center">
        <Descrizione />
        <br />
        <div className="container">
          <div className="row">
            <Card />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default App;
