import React from "react";
import Footer from "../../footer/footer";
import Navbar from "../../navbar/navbarmenu";
import Table from "react-bootstrap/Table";
function TablePrezzi() {
  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>

            <th>(A persona)Senza Colazione</th>
            <th>(A persona)Con Colazione</th>
          </tr>
        </thead>
        <tbody>
          <tr>

            <td>40€</td>
            <td>45€</td>
          </tr>

        </tbody>
      </Table>
    </>
  );
}

function Tariffe() {
  return (
    <>
      <Navbar />
      <div className="container">
        <h1 className="title2">TARIFFE</h1>
        <p>
          I prezzi indicati si intendono a persona a notte e sono comprensivi di
          pulizie finali, riscaldamento, utenze, biancheria letto bagno e
          cucina, cambio biancheria settimanale.
        </p>
        <h4 className="title3">PERNOTTAMENTO A NOTTE</h4>
        <TablePrezzi />

        <div className="serviziagg">
          <h5 style={{ fontWeight: "bold" }}>
            Servizi aggiuntivi su richiesta:
          </h5>
          <p>Letto aggiuntivo : 15€/giorno</p>
          <p>Pulizie giornaliere : 8€/giorno </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Tariffe;
