import React, { useEffect, useState } from "react";
import Logo from "../imm/logo/logo_small.png";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

function Navbar() {
  const [active, setActive] = useState("menu");
  const [iconactive, setIcon] = useState("toggle");

  const [menu, setMenu] = useState([]);
  const navToggle = () => {
    active === "menu" ? setActive("menu active_link") : setActive("menu");

    iconactive === "toggle" ? setIcon("toggle toggl") : setIcon("toggle");
  };

  useEffect(() => {
    const menuData = [{
      id: '1',
      page: '/',
      namemenu: 'HOME',
      active: true
    }, {
      id: '2',
      page: '/Appartamenti',
      namemenu: 'APPARTAMENTI',
      active: true
    }, {
      id: '3',
      page: '/Tariffe',
      namemenu: 'TARIFFE',
      active: true
    }, {
      id: '4',
      page: '/Contatti',
      namemenu: 'CONTATTI',
      active: true
    }]
    setMenu(menuData)
  }, []);
  return (
    <nav className="nb">
      <div className="container-fluid">
        <img className="logo" src={Logo} alt="" />
      </div>
      <div onClick={navToggle} className={iconactive}>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
      <ul className={active}>
        {menu
          .filter((row) => row.active === true)
          .map((row) => {
            return (
              <li key={row.idmenu} className="nav-item">
                <Link className="link" to={row.page}>
                  {row.namemenu}
                </Link>
              </li>
            );
          })}

       {menu.filter((row) => row.active === true).length > 0 ? (
          <li>
            <Link className="link" to="/Prenota">
              <Button variant="contained">Prenota</Button>
            </Link>
          </li>
        ) : (
          ""
        )}
      </ul>
    </nav>
  );
}

export default Navbar;
