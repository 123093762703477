import { api } from "./api";
/*  //menu
const menuGet = () => api.get("/");

//tariffe
const prezziGet = () => api.get("/Prezzi");
*/
//prenotazione
const prenotazione = (params) => api.post("/api/prenotazioni/pren/insert", params);

export { prenotazione };
